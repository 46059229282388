<template>
  <div class="plugin-layout">
    <div ref="pluginDetailsHeader" class="plugin-details-header mt-0">
      <template v-if="plugin">
        <div class="xcontainer">
          <div class="description flex">
            <div class="icon w-24">
              <img
                v-if="plugin.iconUrl"
                :alt="plugin.name + ' icon'"
                :src="plugin.iconUrl"
              />
              <img
                v-else
                alt="Default plugin icon"
                class="w-full"
                src="../assets/images/default-plugin.svg"
              />
            </div>

            <div class="name ml-4 self-center">
              <h1 class="self-center pb-0 border-b-0 mt-0 mb-1">
                {{ plugin.name }}
              </h1>

              <div class="developer flex items-center">
                <c-link
                  :to="'/developer/' + plugin.developerSlug"
                  :title="plugin.developerName"
                >
                  {{ plugin.developerName }}
                </c-link>

                <template v-if="plugin.developerPartner">
                  <partner-badge kind="craft" class="ml-2" />
                </template>
              </div>
            </div>
          </div>

          <div class="tabs mt-6 border-b dark:border-gray-700">
            <ul class="-mb-px flex space-x-6">
              <li v-for="(tab, tabKey) in tabs" :key="tabKey">
                <c-link
                  :class="{
                    'inline-block px-1 py-3 border-b-2': true,
                    'text-gray-500 dark:text-gray-400 dark:text-opacity-75 hover:text-opacity-100 border-transparent hover:border-separator hover:border-gray-200 dark:hover:border-gray-700':
                      route.path !==
                      '/' + plugin.handle + (tab.slug ? '/' + tab.slug : ''),
                    'text-blue-600 dark:text-blue-400 border-blue-600 dark:border-blue-400 text-opacity-100':
                      route.path ===
                      '/' + plugin.handle + (tab.slug ? '/' + tab.slug : ''),
                  }"
                  :to="'/' + plugin.handle + (tab.slug ? '/' + tab.slug : '')"
                >
                  {{ tab.name }}
                </c-link>
              </li>
            </ul>
          </div>
        </div>
      </template>
    </div>

    <div class="xcontainer py-8">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
  import {useRoute} from 'vue-router';
  import PartnerBadge from './partner/PartnerBadge.vue';
  import {computed, usePluginStoreStore} from '#imports';

  const route = useRoute();
  const pluginStoreStore = usePluginStoreStore();

  const plugin = computed(() => pluginStoreStore.plugin);
  const tabs = computed(() => {
    const tabs = [];

    tabs.push({
      name: 'Overview',
      slug: '',
    });

    tabs.push({
      name: 'Reviews',
      slug: 'reviews',
    });

    if (plugin.value?.editions?.[0]?.features?.length > 0) {
      tabs.push({
        name: 'Editions',
        slug: 'editions',
      });
    }

    tabs.push({
      name: 'Changelog',
      slug: 'changelog',
    });

    return tabs;
  });
</script>
